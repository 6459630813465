/**
 * Styles targeting the News and Updates section
 *
 */

/* =News Image Teasers
----------------------------------------------------------*/
 
.image-teaser {
  @include teaser-space;
}

.image-teaser__title {
  @include teaser-title;
}

.image-teaser__date {
  @include teaser-date;
}
 

