/**
 * Styles targeting various site elements
 *
 */

/* =Full Hero Image
----------------------------------------------------------*/

.hero-image--full {
  width: 100%;
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 0 20px;
  @include breakpoint($sm) {
    height: 300px;
  }
}

/* =Headline Links (this should be added to the distro)
----------------------------------------------------------*/

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}



/* =CTA Icons
----------------------------------------------------------*/

.wcm-cta__tile.external-link > .wcm-cta__detail:after {
  content: '';
}

.wcm-cta__tile.external-link .wcm-cta__detail > span {
  &:after {
    content: '\00a0\e811';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* =Applicant Newsletter
----------------------------------------------------------*/

.applicant-newsletter {
  margin: 0 0 30px;
  text-align: center;
  .field-basic-file-text img {
    width: 48px;
    height: 48px;
    margin: 0 0 10px;
  }
}

/* =Equal Heights
-----------------------------------------------------------*/

.display-flex {
  @include breakpoint($sm) {
    @include clearfix();
    display: flex;
    flex-wrap: wrap;
  }
}
.display-flex > [class*='col-'] {
  @include breakpoint($sm) {
    display: flex;
    flex-direction: column;
  }
}

/* =Footer
----------------------------------------------------------*/

.footer-nav {
  @include breakpoint($md) {
    margin: 0 0 20px;
  }
  li {
    line-height: 1.4;
    padding: 0 0 8px;
  }
}

.footer-nav .level-1 {
  @include breakpoint($md) {
    width: (100% / 5);
    float: left;
    padding: 0 10px 5px;
  }
}

