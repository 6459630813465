/**
 * Styles targeting the Video section
 *
 */

/* =Video Index
----------------------------------------------------------*/

.video {
  @include teaser-space;
}

.video__title {
  @include teaser-title;
}
