@charset "UTF-8";
/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * Shared styles to apply to different components
 *
 */
/* =Teaser Styles
----------------------------------------------------------*/
/**
 * Styles targeting Form Components
 *
 */
/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

/**
 * Styles targeting the various site Callouts
 *
 */
/* =Standard
 ----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .paragraphs-items-field-card-callout {
    display: flex;
  }
}

.card-callout {
  margin: 20px 0;
  padding-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .card-callout {
    border: none;
    padding-bottom: 0;
  }
}

.card-callout__wrapper {
  background: #fff;
  box-shadow: 1px 2px 2px 1px #dddddd;
}

.card-callout__text {
  padding: 20px;
}

.card-callout__title {
  margin: 0 0 10px;
  text-align: center;
}

.card-callout__title a {
  color: #b31b1b;
}

.card-callout__title a:hover {
  color: #b31b1b;
  text-decoration: underline;
}

.card-callout__title a:hover:before {
  text-decoration: none;
}

.card-callout__title a:after {
  content: '' !important;
  display: none !important;
}

.card-callout__title a span {
  display: block;
  position: relative;
  top: -40px;
  margin: 0 auto -40px;
}

.card-callout__title a span:before {
  content: '\e802';
  width: 50px;
  height: 50px;
  color: #e7751d;
  background: #fff;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  line-height: 50px;
  display: inline-block;
  -webkit-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

.card-callout__title a.external-link span:before {
  content: '' !important;
  background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 15px 15px;
  margin-left: 6px;
  position: relative;
  top: 2px;
}

.card-callout__title a:hover span:before {
  text-decoration: none;
}

.card-callout__description {
  font-size: 15px;
  text-align: center;
}

.card-callout__image {
  overflow: hidden;
}

.card-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

.card-callout__image img:hover {
  transform: scale(1.1);
}

.card-callout__cta {
  font-size: 14px;
}

.card-callout__cta a {
  border: none;
}

@media screen and (min-width: 992px) {
  .col-md-4 .card-callout .card-callout__text {
    height: 500px;
  }
}

@media screen and (min-width: 1200px) {
  .col-md-4 .card-callout .card-callout__text {
    height: 375px;
  }
}

/* =Long
----------------------------------------------------------*/
.card-callout--long {
  position: relative;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.card-callout--long .card-callout__image {
  overflow: hidden;
}

@media screen and (min-width: 992px) {
  .card-callout--long .card-callout__text {
    padding: 0;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%);
    /* IE 9 */
    transform: translateY(-50%);
    /* IE 10, Fx 16+, Op 12.1+ */
  }
}

.card-callout--long .card-callout__title,
.card-callout--long .card-callout__description {
  text-align: left;
}

@media screen and (min-width: 992px) {
  .card-callout--long .card-callout__description {
    width: 85%;
  }
}

.card-callout--long .indicator {
  display: none;
}

@media screen and (min-width: 992px) {
  .card-callout--long .indicator {
    display: block;
    position: absolute;
    height: 50%;
    top: 25%;
    right: 5%;
    border-left: 1px solid #ddd;
    border-bottom: none;
    padding-left: 20px;
    z-index: 10;
  }
}

.card-callout--long .indicator:after {
  content: "";
  position: relative;
  top: 33%;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #e7751d;
}

.card-callout--long .indicator:hover:after {
  border-color: transparent transparent transparent #cf4520;
}

/* =Top Hospitals
----------------------------------------------------------*/
.top-hospitals {
  margin: 30px 0;
  text-align: center;
}

.top-hospitals .top-hospitals__award__icon {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border-width: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  background: #fff;
  margin: 0 0 15px;
}

.top-hospitals .top-hospitals__award__icon:after {
  content: '';
}

.top-hospitals .top-hospitals__award__icon > span {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #e7751d;
  font-size: 40px;
  font-family: "1898Sans-Bold", sans-serif;
}

.top-hospitals .top-hospitals__award__icon > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55px;
  height: 55px;
}

/* =Residency Program
----------------------------------------------------------*/
.residency-landing-nav {
  background: #F6F6F6;
  padding: 30px;
  margin: 0 0 30px;
}

.residency-landing-nav__hero__img {
  overflow: hidden;
}

.residency-landing-nav__hero__img img {
  transition: all 0.3s ease 0s;
}

@media screen and (min-width: 992px) {
  .residency-landing-nav__hero__img img:hover {
    transform: scale(1.1);
  }
}

.residency-landing-nav__list__item:after {
  content: "";
  display: block;
  width: 33%;
  padding-top: 8px;
  border-bottom: 2px solid #dddddd;
}

.residency-landing-nav__list__item:first-child > h3 {
  margin-top: 0;
}

.residency-landing-nav__list__item:last-child:after {
  padding-top: 0;
  border: none;
}

/**
 * Styles targeting various site elements
 *
 */
/* =Full Hero Image
----------------------------------------------------------*/
.hero-image--full {
  width: 100%;
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 0 20px;
}

@media screen and (min-width: 768px) {
  .hero-image--full {
    height: 300px;
  }
}

/* =Headline Links (this should be added to the distro)
----------------------------------------------------------*/
h1 a:hover, .h1 a:hover, h2 a:hover, .h2 a:hover, h3 a:hover, .h3 a:hover,
h4 a:hover, .h4 a:hover, h5 a:hover, .h5 a:hover, h6 a:hover, .h6 a:hover {
  text-decoration: underline;
}

/* =CTA Icons
----------------------------------------------------------*/
.wcm-cta__tile.external-link > .wcm-cta__detail:after {
  content: '';
}

.wcm-cta__tile.external-link .wcm-cta__detail > span:after {
  content: '\00a0\e811';
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* =Applicant Newsletter
----------------------------------------------------------*/
.applicant-newsletter {
  margin: 0 0 30px;
  text-align: center;
}

.applicant-newsletter .field-basic-file-text img {
  width: 48px;
  height: 48px;
  margin: 0 0 10px;
}

/* =Equal Heights
-----------------------------------------------------------*/
@media screen and (min-width: 768px) {
  .display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .display-flex:before, .display-flex:after {
    content: " ";
    display: table;
  }
  .display-flex:after {
    clear: both;
  }
}

@media screen and (min-width: 768px) {
  .display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
}

/* =Footer
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .footer-nav {
    margin: 0 0 20px;
  }
}

.footer-nav li {
  line-height: 1.4;
  padding: 0 0 8px;
}

@media screen and (min-width: 992px) {
  .footer-nav .level-1 {
    width: 20%;
    float: left;
    padding: 0 10px 5px;
  }
}

/**
 * Styles targeting the Event sections
 *
 */
/* =Event Teasers
----------------------------------------------------------*/
.wcm-event {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd;
}

.wcm-event:last-child {
  border: none;
}

.wcm-event__title {
  font-size: 20px;
  padding: 0 0 10px;
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
}

.wcm-event__title a {
  color: #b31b1b;
}

.wcm-event__date {
  text-transform: uppercase;
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  padding-bottom: 10px;
}

.wcm-event__description {
  margin: 0 0 20px;
}

/**
 * Styles targeting the News and Updates section
 *
 */
/* =News Image Teasers
----------------------------------------------------------*/
.image-teaser {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd;
}

.image-teaser:last-child {
  border: none;
}

.image-teaser__title {
  font-size: 20px;
  padding: 0 0 10px;
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
}

.image-teaser__title a {
  color: #b31b1b;
}

.image-teaser__date {
  text-transform: uppercase;
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  padding-bottom: 10px;
}

/**
 * Styles targeting the News and Updates section
 *
 */
/* =Trial Index
----------------------------------------------------------*/
.wcm-clinical-trials {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd;
}

.wcm-clinical-trials:last-child {
  border: none;
}

.wcm-clinical-trials__title {
  font-size: 20px;
  padding: 0 0 10px;
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
}

.wcm-clinical-trials__title a {
  color: #b31b1b;
}

.wcm-clinical-trials__details {
  margin: 10px 0;
}

.wcm-clinical-trials__details a {
  display: inline-block;
  background-color: #cf4520;
  border-radius: 7px;
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
  color: #fff;
  margin-right: 10px;
  font-size: 13px;
}

.wcm-clinical-trials__details a:hover {
  background-color: #b31b1b;
  border-color: #b31b1b;
}

/**
 * Styles targeting the Video section
 *
 */
/* =Video Index
----------------------------------------------------------*/
.video {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd;
}

.video:last-child {
  border: none;
}

.video__title {
  font-size: 20px;
  padding: 0 0 10px;
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
}

.video__title a {
  color: #b31b1b;
}

/**
 * Styles targeting the Resident Research content type
 *
 */
/* =Research Highlights
----------------------------------------------------------*/
.research-highlight {
  margin: 0 0 30px;
}

.research-highlight__title {
  font-size: 30px;
  font-family: "1898Sans-Bold", sans-serif;
  font-weight: normal;
  line-height: 1.1;
  color: #b31b1b;
}

/**
 * Styles targeting the Person content type
 *
 */
/* =Grid View
----------------------------------------------------------*/
.view-people-list {
  margin: 20px 0;
}

.view-people-list__category {
  margin: 20px 0;
}

.view-people-list .wcm-person {
  margin: 0 0 20px;
}

@media screen and (min-width: 768px) {
  .view-people-list .wcm-person {
    margin: 0 0 40px;
  }
}

.view-people-list .break {
  width: 100%;
  margin: 0 0 20px;
  border-bottom: 1px solid #dddddd;
}

.wcm-person__name {
  font-weight: 700;
}

.wcm-person__title {
  font-style: italic;
  line-height: 1.5;
  margin: 10px 0 0;
}

.wcm-person__profile-links {
  margin: 10px 0 0;
}

.wcm-person__bio-toggle {
  color: #cf4520;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
}

.wcm-person__bio-toggle:after {
  content: '+';
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
}

.wcm-person__bio-toggle--open:after {
  content: '–';
}

.wcm-person__bio {
  display: none;
  margin-top: 20px;
  max-width: 860px;
  padding: 15px;
  background: #f7f7f7;
  position: relative;
}

.wcm-person__bio:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #f7f7f7 transparent;
  position: absolute;
  top: -15px;
}

/* =Women's Brain Initiative
 ----------------------------------------------------------*/
.wcm-person__bio--wbi {
  font-size: 13px;
  font-style: italic;
}

/* =Dist. Residents (Reveal Teasers)
 ----------------------------------------------------------*/
.wcm-reveal-teaser .wcm-reveal-teaser__image,
.wcm-reveal-teaser .wcm-reveal-teaser__detail {
  margin: 20px 0;
}

.view-display-id-dist_residents_list .wcm-person {
  margin: 0;
}

.dist-residents__title {
  margin: 20px 0;
}

.research-highlight__residents span {
  font-weight: bold;
}

/* =Fellows (Reveal Teasers)
 ----------------------------------------------------------*/
.view-people-list__fellow-group.element__toggle--open {
  margin: 0 0 20px;
}

/* =People Sort
 ----------------------------------------------------------*/
.view-draggable-sorts {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.view-draggable-sorts:before, .view-draggable-sorts:after {
  content: " ";
  display: table;
}

.view-draggable-sorts:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .view-draggable-sorts {
    width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .view-draggable-sorts {
    width: 970px;
  }
}

@media screen and (min-width: 1200px) {
  .view-draggable-sorts {
    width: 1170px;
  }
}

/**
 * Styles targeting the Biblio-related content
 *
 */
/* =Publications Index
 ----------------------------------------------------------*/
.publication-teaser {
  margin: 0 0 10px;
}

/* =Residents Research
 ----------------------------------------------------------*/
.view-publications__group {
  color: #000000;
  font-weight: bold;
  background-color: #e1e1e1;
  border: 1px solid #ccc;
  padding: 10px 15px 5px;
  margin: 20px 0;
}

/**
 * Styles targeting the homepage
 *
 */
/* =Primary Content
----------------------------------------------------------*/
.front .primary-content {
  background: #f7f7f7;
}

/* =Home Hero
----------------------------------------------------------*/
.home-hero {
  position: relative;
}

.home-hero img {
  width: 100%;
  height: auto;
}

.home-hero .field-hero-headline {
  font-size: 18px;
  font-family: "1898Sans-Bold", sans-serif;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .home-hero .field-hero-headline {
    padding: 0 30px 0;
  }
}

@media screen and (min-width: 768px) {
  .home-hero .field-hero-headline {
    font-size: 30px;
    padding: 0 15% 10px;
  }
}

@media screen and (min-width: 992px) {
  .home-hero .field-hero-headline {
    font-size: 36px;
    padding: 0 15% 10px;
  }
}

.home-hero .field-hero-cta {
  display: none;
  position: absolute;
  bottom: 15%;
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .home-hero .field-hero-cta {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .home-hero .field-hero-cta {
    bottom: 25%;
  }
}

.home-hero .field-hero-cta a {
  display: inline-block;
  background: #cf4520;
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 20px;
}

.home-hero .field-hero-cta a:first-child {
  margin-right: 100px;
}

.home-hero .field-hero-cta a:hover {
  background: #b31b1b;
}

/* =Home Rankings
----------------------------------------------------------*/
.home-rankings {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.home-rankings:before, .home-rankings:after {
  content: " ";
  display: table;
}

.home-rankings:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .home-rankings {
    width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .home-rankings {
    width: 970px;
  }
}

@media screen and (min-width: 1200px) {
  .home-rankings {
    width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .home-rankings .top-hospitals {
    position: relative;
    top: -100px;
    margin-bottom: -80px;
    overflow: hidden;
  }
}

/* =Home Message
----------------------------------------------------------*/
.home-message {
  text-align: center;
  padding: 0 0 30px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.home-message:before, .home-message:after {
  content: " ";
  display: table;
}

.home-message:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .home-message {
    width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .home-message {
    width: 970px;
  }
}

@media screen and (min-width: 1200px) {
  .home-message {
    width: 1170px;
  }
}

.home-message h1 {
  font-size: 30px;
  margin: 0 0 10px;
}

.home-message p {
  color: #555555;
  font-family: "1898Sans-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  padding: 0 17.5%;
}

/* =Secondary Content
----------------------------------------------------------*/
.front .secondary-content {
  background: url("/sites/all/themes/wcm_dept_neuro/images/home-education-bg.jpg") center center no-repeat;
  background-size: cover;
  margin: 10px 0 0;
  padding: 30px 0;
}

@media screen and (min-width: 992px) {
  .front .secondary-content > .container > .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.home-secondary-content-one {
  color: #fff;
  text-align: center;
  padding: 0 30px;
  margin: 0 0 20px;
}

@media screen and (min-width: 992px) {
  .home-secondary-content-one {
    margin: 0;
  }
}

.home-secondary-content-one h3 a {
  color: #fff;
}

.home-secondary-content-two {
  background: #fff;
  padding: 20px 30px;
}

.home-news .view-header h2 {
  margin-bottom: 20px;
}

/* =Home Callouts
----------------------------------------------------------*/
.education__title {
  padding: 20px 0;
}

.twitter {
  position: relative;
}

.twitter__follow a {
  position: absolute;
  top: 0;
  right: 10px;
  border: none;
}

.twitter__follow a:before {
  content: '\e807';
  color: #cf4520;
  padding-right: 8px;
  font-size: 28px;
  vertical-align: middle;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5;
}

.twitter__follow a:after {
  content: '' !important;
}

.twitter__follow a:hover {
  text-decoration: underline;
}
