/**
 * Styles targeting the homepage
 *
 */


/* =Primary Content
----------------------------------------------------------*/

.front .primary-content {
  background: $wcm-bg-gray;
}

/* =Home Hero
----------------------------------------------------------*/

.home-hero {
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .field-hero-headline {
    font-size: 18px;
    font-family: $wcm-bold;
    line-height: 1.2;
    color: $wcm-white;
    text-align: center;
    text-shadow: 1px 2px 5px rgba(0,0,0,0.6);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 10;
    @include breakpoint($xs) {
      padding: 0 30px 0;
    }
    @include breakpoint($sm) {
      font-size: 30px;
      padding: 0 15% 10px;  
    }
    @include breakpoint($md) {
      font-size: 36px;
      padding: 0 15% 10px;
    }
  }
  .field-hero-cta {
    display: none;
    position: absolute;
    bottom: 15%;
    width: 100%;
    text-align: center;
    @include breakpoint($md) {
      display: block;
    }
    @include breakpoint($lg) {
      bottom: 25%;
    }
    a {
      display: inline-block;
      background: $wcm-dark-orange;
      color: $wcm-white;
      border: none;
      padding: 8px 20px;
      border-radius: 20px;
      &:first-child {
        margin-right: 100px;
      }
      &:hover {
        background: $wcm-red;
      }
    }
  }
}

/* =Home Rankings
----------------------------------------------------------*/

.home-rankings {
  @include container-wrap();
  .top-hospitals {
    @include breakpoint($lg) {
      position: relative;
      top: -100px;
      margin-bottom: -80px;
      overflow: hidden;
    }
  }
}

/* =Home Message
----------------------------------------------------------*/

.home-message {
  text-align: center;
  padding: 0 0 30px;
  @include container-wrap(); 
  h1 {
    font-size: 30px;
    margin: 0 0 10px;
  }
  p {
    color: $wcm-dark-gray;
    font-family: $wcm-regular;
    font-size: 20px;
    line-height: 1.5;
    padding: 0 17.5%;
  }
}

/* =Secondary Content
----------------------------------------------------------*/

.front .secondary-content {
  background: url('/sites/all/themes/wcm_dept_neuro/images/home-education-bg.jpg') center center no-repeat;
  background-size: cover;
  margin: 10px 0 0;
  padding: 30px 0;
  > .container > .row {
    @include breakpoint($md) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.home-secondary-content-one {
  color: $wcm-white;
  text-align: center;
  padding: 0 30px;
  margin: 0 0 20px;
  @include breakpoint($md) {
    margin: 0;
  }
  h3 a {
    color: $wcm-white;
  }
}

.home-secondary-content-two {
  background: $wcm-white;
  padding: 20px 30px;
}

.home-news {
  .view-header h2 {
    margin-bottom: 20px;
  }
}

/* =Home Callouts
----------------------------------------------------------*/

.education__title {
  padding: 20px 0;
}

.twitter {
  position: relative;
}

.twitter__follow {
  a {
    position: absolute;
    top: 0;
    right: 10px;
    border: none;
    &:before {
      content:'\e807';
      color: $wcm-dark-orange;
      padding-right: 8px;
      font-size: 28px;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
    &:after {
      content: '' !important;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}










