/**
 * Styles targeting the Person content type
 *
 */

$space: 1rem;

@mixin mini-breakout($v-padding: $space) {
  margin-right: calc(-50vw + 50%);
}

/* =Grid View
----------------------------------------------------------*/
 
.view-people-list {
  margin: 20px 0; 
  &__category {
    margin: 20px 0;
  }
  .wcm-person {
    margin: 0 0 20px;
    @include breakpoint($sm) {
      margin: 0 0 40px;
    }
  }
  .break {
    width: 100%;
    margin: 0 0 20px;
    border-bottom: 1px solid $wcm-border-gray;
  }
 }
 
.wcm-person__name {
  font-weight: 700;
}

.wcm-person__title {
  font-style: italic;
  line-height: 1.5;
  margin: 10px 0 0;
}

.wcm-person__profile-links {
  margin: 10px 0 0;
}

.wcm-person__bio-toggle {
  color: $wcm-dark-orange;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  &:after {
    content: '+';
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
  }
  &--open {
    &:after {
      content: '–';
    }
  }
}

.wcm-person__bio {
  display: none;
  margin-top: 20px;
  max-width: 860px;
  padding: 15px;
  background: $wcm-bg-gray;
  position: relative;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #f7f7f7 transparent;
    position: absolute;
    top: -15px;
  }
 }

 /* =Women's Brain Initiative
 ----------------------------------------------------------*/
 
 .wcm-person__bio--wbi {
  font-size: 13px;
  font-style: italic;
 }

 /* =Dist. Residents (Reveal Teasers)
 ----------------------------------------------------------*/

 .wcm-reveal-teaser {
   .wcm-reveal-teaser__image,
   .wcm-reveal-teaser__detail {
     margin: 20px 0;
   }
 }

 .view-display-id-dist_residents_list .wcm-person {
   margin: 0;
 }

.dist-residents__title {
  margin: 20px 0;
}

.research-highlight__residents {
  span {
    font-weight: bold;
  }
}

 /* =Fellows (Reveal Teasers)
 ----------------------------------------------------------*/
 
 .view-people-list__fellow-group.element__toggle--open {
   margin: 0 0 20px;
 }
 

 /* =People Sort
 ----------------------------------------------------------*/

 .view-draggable-sorts {
   @include container-wrap;
 }
 
 
 
 
