/**
 * Styles targeting the various site Callouts
 *
 */

 /* =Standard
 ----------------------------------------------------------*/

.paragraphs-items-field-card-callout {
  @include breakpoint($md) {
    display: flex;
  }
}

.card-callout {
  margin: 20px 0;
  padding-bottom: 20px;
  @include breakpoint($md) {
    border: none;
    padding-bottom: 0;
  }
}

.card-callout__wrapper {
  background: #fff;
  box-shadow: 1px 2px 2px 1px #dddddd;
}

.card-callout__text {
  padding: 20px;
}

.card-callout__title {
  margin: 0 0 10px;
  text-align: center;
  a {
    color: $wcm-red;
    &:hover {
      color: $wcm-red;
      text-decoration: underline;
      &:before {
        text-decoration: none;
      }
    }
    &:after {
      content: '' !important;
      display: none !important;
    }
  }
  a span {
    display: block;
    position: relative;
    top: -40px;
    margin: 0 auto -40px;
  }
  a span:before {
    content: '\e802';
    width: 50px;
    height: 50px;
    color: $wcm-bright-orange;
    background: #fff;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    @include fontello();
    line-height: 50px;
    display: inline-block;
    @include transition(background-color .25s);
  }
  a.external-link span:before {
    content: '' !important;
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 15px 15px;
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
  a:hover span:before {
    text-decoration: none;
  }
}

.card-callout__description {
  font-size: 15px;
  text-align: center;
}

.card-callout__image {
  overflow: hidden;
}

.card-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
}

.card-callout__cta {
  font-size: 14px;
  a {
    border: none;
  }
}

// For 4 col cards, set explicit height. Design request.
.col-md-4 .card-callout .card-callout__text {
  @include breakpoint($md) {
    height: 500px;
  }
  @include breakpoint($lg) {
    height: 375px;
  }
}

/* =Long
----------------------------------------------------------*/
 
 .card-callout--long {
  position: relative;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  .card-callout__image {
    overflow: hidden;
  }
  .card-callout__text {
    @include breakpoint($md) {
      padding: 0;
      @include v-center();
    }
  }
  .card-callout__title,
  .card-callout__description {
    text-align: left;
  }
  .card-callout__description {
    @include breakpoint($md) {
      width: 85%;
    }
  }
  .indicator {
    display: none;
    @include breakpoint($md) {
      display: block;
      position: absolute;
      height: 50%;
      top: 25%;
      right: 5%;
      border-left: 1px solid #ddd;
      border-bottom: none;
      padding-left: 20px;
      z-index: 10;
    }
    &:after {
      content: "";
      position: relative;
      top: 33%;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent $wcm-bright-orange;
    }
    &:hover:after {
      border-color: transparent transparent transparent $wcm-dark-orange;
    }
  }
}

/* =Top Hospitals
----------------------------------------------------------*/

.top-hospitals {
  margin: 30px 0;
  text-align: center;
  .top-hospitals__award__icon {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border-width: 0;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    background: #fff;
    margin: 0 0 15px;
    &:after {
      content: '';
    }
    > span {
      position: absolute;
      top: 53%;
      left: 50%;
      transform: translate(-50%, -50%); 
      color: $wcm-bright-orange;
      font-size: 40px;
      font-family: $wcm-bold;
    }
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 55px;
      height: 55px;
    }
  }
}

/* =Residency Program
----------------------------------------------------------*/

.residency-landing-nav {
  background:#F6F6F6;
  padding: 30px;
  margin: 0 0 30px;
}

.residency-landing-nav__hero__img {
  overflow: hidden;
  img {
    transition: all 0.3s ease 0s;
    &:hover {
      @include breakpoint($md) {
        transform: scale(1.1);
      }
    }
  }
}

.residency-landing-nav__list__item {
  &:after {
    content: "";
    display: block;
    width: 33%;
    padding-top: 8px;
    border-bottom: 2px solid $wcm-border-gray;
  }
}

.residency-landing-nav__list__item:first-child {
  > h3 {
    margin-top: 0;
  }
}

.residency-landing-nav__list__item:last-child {
  &:after {
    padding-top: 0;
    border: none;
  }
}


