/**
 * Styles targeting the Event sections
 *
 */

/* =Event Teasers
----------------------------------------------------------*/

.wcm-event {
  @include teaser-space;
}

.wcm-event__title {
  @include teaser-title;
}

.wcm-event__date {
  @include teaser-date;
}

.wcm-event__description {
  margin: 0 0 20px;
}
