/**
 * Styles targeting the News and Updates section
 *
 */

/* =Trial Index
----------------------------------------------------------*/

.wcm-clinical-trials {
  @include teaser-space;
}

.wcm-clinical-trials__title {
  @include teaser-title;
}

.wcm-clinical-trials__details {
  margin: 10px 0;
  a {
    display: inline-block;
    background-color: #cf4520;
    border-radius: 7px;
    height: 30px;
    line-height: 30px;
    padding: 0 14px;
    color: #fff;
    margin-right: 10px;
    font-size: 13px;
    &:hover {
      background-color: $wcm-red;
      border-color: $wcm-red;
    }
  }
}

