/**
 * Styles targeting the Resident Research content type
 *
 */


 /* =Research Highlights
----------------------------------------------------------*/

.research-highlight {
  margin: 0 0 30px;
}

.research-highlight__title {
  font-size: 30px;
  font-family: $wcm-bold;
  font-weight: normal;
  line-height: 1.1;
  color: $wcm-red;
}


